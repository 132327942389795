import Swiper from 'swiper';
import { Navigation, Pagination, Keyboard } from 'swiper/modules';

class SwiperSlider {
  constructor(gallerySlider) {
    const slideElement = gallerySlider.querySelector('.swiper-gallery');
    const slideButtons = gallerySlider.querySelectorAll('.swiper-nav');
    const swiperImgs = gallerySlider.querySelectorAll('img');

    swiperImgs.forEach((img) => {
      img.classList.add('swiper-lazy');
    });

    slideButtons.forEach((button) => {
      gallerySlider.addEventListener('mouseover', () => { button.classList.add('active'); });
      gallerySlider.addEventListener('mouseleave', () => { button.classList.remove('active'); });
    });

    if (slideElement) {
      const swiperSlider = new Swiper(slideElement, { // eslint-disable-line no-unused-vars
        modules: [Navigation, Pagination, Keyboard],
        preloadImages: false,
        lazy: true,
        loop: false,
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        thumbs: false,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          renderBullet(index, className) {
            return `<li class="s${index} ${className}">${index + 1}</li>`;
          },
        },
        keyboard: {
          enabled: true,
          onlyInViewport: false,
        },
      });
    }
  }
}

export default SwiperSlider;
