/* eslint-disable no-unused-vars */
import SwiperSlider from './vendor/SwiperSlider';

document.addEventListener('DOMContentLoaded', () => {
  // content sliders
  const gallerySliders = document.querySelectorAll('.ce_gallery');

  gallerySliders.forEach((gallerySlider) => {
    const swiperSlider = new SwiperSlider(gallerySlider);
  });
});
